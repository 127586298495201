<template>
    <div class="form-wrap1">
        <div class="logo">
            <img src="../assets/images/bentley_logo_a-c1@2x.png"  alt="" />
        </div>
        <div class="top-bg"></div>
        <div class="form-wrap">
            <div class="form-item">
                <div class="input-item">
                    <span>GRP ID</span>
                    <input v-model="hubId" type="text" placeholder="请输入GRP ID">
                </div>
            </div>
            <div class="form-item">
                <div class="input-item">
                    <span>密码</span>
                    <input v-model="password" type="text" placeholder="请输入密码">
                </div>
            </div>
            <div class="form-item">
                <div class="input-item2">
                    <input class="input-radio" v-model="declare" type="radio" value=1/>
                    <span>本人已仔细阅读并同意
                    <router-link :to="{name:'UserAgreement', params: {hubId: this.hubId, password: this.password, declare: this.declare}}" style="color:rgba(0,50,32,0.7)">《宾利经销商员工培训线上报名系统个人信息保护声明》</router-link></span>
                </div>
            </div>
            <div class="login-btn" @click="handleLogin">下一步</div>
            <div style="margin:50px;">
                <span style="font-size:14px;">简体版</span> / 
                <a href="https://hk.bentley.levsoft.cn/#/login" style="font-size:14px;color: rgba(0, 50, 32, 0.7);">繁體版</a>
            </div>
        </div>
    </div>
</template>
<script>
import {Landing} from '@/network'
import {Toast} from 'vant'
import {Charon} from '@/utils'

const MAX_COUNT = 60
export default {
    data() {
        return {
            staffNumber: null,
            hubId: sessionStorage.getItem('hubId') || this.$route.params.hubId || null,
            password: this.$route.params.password || null,
            count: 0,
            maxCount: MAX_COUNT,
            disabledCodeBtn: false,
            declare: this.$route.params.declare || 0,
        }
    },
    methods: {
        async handleLogin() {
            if (!this.hubId) return Toast('请输入GRP ID')
            if (!this.password) return Toast('请输入密码')
            if (!this.declare) return Toast('请确认本人已仔细阅读并同意《宾利经销商员工培训线上报名系统个人信息保护声明》')
            Landing.userLogin({
                hubId: this.hubId, 
                password: this.password, 
            }).then(({data}) => {
                Charon.defaults.headers.common['token'] = data.token
                sessionStorage.setItem('token', data.token)
                if (data.phone)
                    sessionStorage.setItem('phone', data.phone)
                sessionStorage.setItem('hubId', data.hubId)
                sessionStorage.setItem('loginType', 'user')
                if (data.phone) {
                    Landing.getCourseEnrollmentByPhone({phone: data.phone}).then(enrollment => {
                        if (!enrollment) {
                            Landing.getReservation({phone: data.phone}).then(data2 => {
                                if (!data2) {
                                    if (data.skipForm != 1) {
                                        this.$router.push({name: 'Form', params: {phone: data.phone}})
                                    } else {
                                        if (this.jobRole == '总经理') {
                                            this.$router.replace({name: 'GMFirst'})
                                        } else {
                                            this.$router.replace({name: 'AfterForm'})
                                        }
                                    }
                                } else {
                                    if (data && data.appointmentDate && this.getDaysBetween2(data.appointmentDate) >= 3) {
                                        this.$router.push({name: 'Enrollment', params: {phone: this.phone}})
                                    } else {
                                        //this.$router.push({name: 'Finish', params: {phone: this.phone}})
                                        this.$router.push({name: 'Enrollment', params: {phone: this.phone}})
                                    }
                                }
                            })
                        } else {
                            this.$router.push({name: 'Enrollment', params: {phone: this.phone}})
                        }
                    })
                } else {
                    if (data.skipForm != 1) {
                        this.$router.push({name: 'Form', params: {phone: data.phone}})
                    } else {
                        if (this.jobRole == '总经理') {
                            this.$router.replace({name: 'GMFirst'})
                        } else {
                            this.$router.replace({name: 'AfterForm'})
                        }
                    }
                }

            }).catch(e => {
                console.warn('response', e.response)
                Toast(e.response.data.message)
            })
        },
        getDaysBetween2(dateString){
          var  endDate = Date.parse(dateString);
          var days=(new Date() - endDate)/(1*24*60*60*1000);
          return  days;
        }
    }
}
</script>
<style lang="scss" scoped>
.form-wrap1 {
    width: 100%;
    height: 100%;
}
.logo {
    width: 375px;
    height: 80px;
    background-color: white;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    img {
        width: 84px;
        height: 42px;
        margin: 20px 20px;
    }
}
.top-bg {
    width: 100vw;
    height: 130px;
    background-image: url('~@/assets/images/login-header-bg.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.form-wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.form-item {
    width: 100%;
    height: auto;
    font-size: 12px;
    padding-bottom: 25px;
    .input-item {
        width: 100%;
        height: 35px;
        background-color: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
            width: 65px;
            text-align: right;
            color: #000;
            margin-right: 10px;
            font-size: 14px;
        }
        input {
            width: 225px;
            height: 32px;
            padding: 0px 5px;
            line-height: 32px;
            font-size: 14px;
            border: 0;
            outline: none;
        }
    }
    .input-item2 {
      width: 100%;
      height: 30px;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .input-radio {
        margin-right: 10px;
      }
    }
    &.code-box {
        width: 100%;
        height: auto;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .input-item {
            width: 190px;
            input {
                width: 100px;
            }
        }
        .code-btn{
            width: 120px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0,50,32,0.7);
            border-radius: 4px;
            font-size: 16px;
            color: #fff;
        }
    }
}
.login-btn {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,50,32,0.7);
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
}
</style>